import store from "@/db/store/index";
import { questions } from "../ts/db-questions";
import api from "../ts/api";
import ServerOutcome from "../ts/outcome";
import { constants } from "../ts/constants";
import Pot from "@/db/ts/assets/pot";
import PotType from "@/db/ts/assets/potType";
import { storedAnswerResponseReader } from "./storedAnswerResponseReader";

class OutcomeEvaluator {
  public async UserShouldConsiderTransfer(): Promise<boolean> {
    const isInIllHealth = questions.getIllHealthQuestionAnswer();
    const hasShortenedLifeExpectancy = questions.getHasShortenedLifeExpectancyAnswer();
    const userHasTakenShortcutThroughQuestions =
      isInIllHealth || hasShortenedLifeExpectancy;
    if (userHasTakenShortcutThroughQuestions) {
      this.LogYesOutcome("In ill health");
      return isInIllHealth || hasShortenedLifeExpectancy;
    }

    const hasStatePension = store.state.statePensionData.hasPension();
    const hasOtherAssets = store.state.allOtherAssets.length > 0;
    const wantsIncomeFlexibility = questions.getIncomeFlexibility();
    const acceptsInvestmentRisk =
      questions.getViewOnRisk() == constants.Risk.Yes;
    const hasFinancialDeficit = store.state.hasFinancialDeficit;
    const debt = questions.getDebt();
    const reducedPensionCash = store.state.calculatedReducedPensionCash;
    let valueOfOtherPensionPots = 0;
    store.state.allOtherAssets.forEach(element => {
      const thisPot: Pot = element;
      if (thisPot.potType == PotType.Pension) {
        valueOfOtherPensionPots += thisPot.currentValue;
      }
    });

    if (
      this.IsInSeriousFinancialDifficulty(
        debt,
        reducedPensionCash,
        valueOfOtherPensionPots
      )
    ) {
      this.LogYesOutcome("serious financial difficulty");
      return true;
    }

    const hasntAnyOtherAssets = !hasStatePension && !hasOtherAssets;
    if (hasntAnyOtherAssets) {
      this.LogNoOutcome("hasn't any other assets");
      return false;
    }

    if (!wantsIncomeFlexibility) {
      this.LogNoOutcome("wants income flexibility");
      return false;
    }

    if (!acceptsInvestmentRisk) {
      this.LogNoOutcome("doesn't accept investment risk");
      return false;
    }

    if (!hasFinancialDeficit) {
      this.LogNoOutcome("hasn't a financial deficit with current situation");
      return false;
    }

    this.LogYesOutcome("is a viable candidate");
    return true;
  }

  public IsInSeriousFinancialDifficulty(
    debt: number,
    reducedPensionCash: number,
    valueOfOtherPensionPots: number
  ) {
    const taxFreePartOfOtherPensions = 0.25 * valueOfOtherPensionPots;
    const liquidAssets = reducedPensionCash + taxFreePartOfOtherPensions;
    const isInDifficulty = debt > liquidAssets;
    return isInDifficulty;
  }

  private async LogYesOutcome(reason: string) {
    await this.LogOutcome(true, reason);
  }

  private async LogNoOutcome(reason: string) {
    await this.LogOutcome(false, reason);
  }

  private async LogOutcome(outcome: boolean, reason: string) {
    const outcomeDesc = outcome ? "yes" : "no";

    const outcomeForServer: ServerOutcome = outcome
      ? ServerOutcome.ShownAdvisors
      : ServerOutcome.NotAppropriate;

    const isOrigen = store.state.configuration.advisorFilter === "origen";
    if (isOrigen) {
      store.commit("updateServerOutcome", outcomeForServer);
      return;
    }

    const userId = store.state.userId;
    const setTerminusPromise = api
      .setTerminus(userId, outcomeForServer)
      .then((res: any) => {
        store.commit("updateServerOutcome", outcomeForServer);
        const storedAnswerResponse = storedAnswerResponseReader.read(res);
        store.commit("updateServerAnswers", storedAnswerResponse.serverAnswers);
        store.commit("updateServerPots", storedAnswerResponse.serverPots);
        store.commit(
          "updateServerStatePension",
          storedAnswerResponse.statePension
        );
      });
    await setTerminusPromise;
  }
}

export default OutcomeEvaluator;
