import AnswerPresentationType from "./answer-presentation-type";
import AnswerValidator from "./answer-validator";
import AnswerValidationResponse from "./answer-validation-response";
import WorkflowSection from "./workflow-section";
import { FollowUpQuestionEvaluator } from "./questions/FollowUpQuestionEvaluator";
import ServerOutcome from "./outcome";
import { FollowUp, TerminationFollowUp } from "./questions/FollowUp";

export const enum AnswerFollowupAction {
  NextQuestion,
  UserBlocked,
  QuestionsCompleted
}

export const enum QuestionNavigationAction {
  CurrentQuestionUnanswered,
  NextQuestion,
  UserBlocked,
  QuestionsCompleted
}

class NextQuestionResponse {
  public questionNavigationAction: QuestionNavigationAction;
  public nextQuestion: Question;
  public workflowSection: WorkflowSection;
  public outcome: ServerOutcome;

  constructor(
    questionNavigationAction: QuestionNavigationAction,
    nextQuestion: Question,
    workflowSection: WorkflowSection,
    outcome: ServerOutcome
  ) {
    this.questionNavigationAction = questionNavigationAction;
    this.nextQuestion = nextQuestion;
    this.workflowSection = workflowSection;
    this.outcome = outcome;
  }
}

class PreviousQuestionResponse {
  public previousQuestion: Question;
  public noPreviousQuestion: boolean;

  constructor(previousQuestion: Question) {
    this.previousQuestion = previousQuestion;
    this.noPreviousQuestion = previousQuestion == undefined;
  }
}

abstract class CustomQuestionValidator {
  public abstract isValid(answers: Array<Answer>): boolean;

  public abstract getFailureMessage(answers: Array<Answer>): string;
}

abstract class QuestionTextProvider {
  public abstract getText(question: Question): string;

  public setText(newText: string) {
    throw new Error("Custom implementation must be provided.");
  }
}

class DefaultQuestionTextProvider extends QuestionTextProvider {
  private text = "";

  constructor(text: string) {
    super();
    this.text = text;
  }

  public getText(question: Question): string {
    return this.text;
  }

  public setText(newText: string) {
    this.text = newText;
  }
}

class ConfirmationBehaviour {
  public IsConfirmationRequired(question: Question): boolean {
    return false;
  }

  public GetConfirmationText(question: Question): string {
    return "Are you sure you want to proceed?";
  }
}

class Question {
  public id = 0;
  public text = "";
  public answers: Array<Answer>;
  public selectedAnswer: Answer | undefined;
  public isKeyQuestion = false;
  public guid: string;
  public canGoBack = true;
  public confirmationBehaviour: ConfirmationBehaviour;
  public isHidden: boolean;
  private textProvider: QuestionTextProvider;
  private customQuestionValidator: CustomQuestionValidator;

  constructor(
    id: number,
    textProvider: QuestionTextProvider,
    isKeyQuestion = false,
    canGoBack = true,
    customQuestionValidator: CustomQuestionValidator = null
  ) {
    this.id = id;
    this.textProvider = textProvider;
    this.answers = new Array<Answer>();
    this.isKeyQuestion = isKeyQuestion;
    this.canGoBack = canGoBack;
    this.customQuestionValidator = customQuestionValidator;
    this.confirmationBehaviour = new ConfirmationBehaviour();
  }

  public getText(): string {
    return this.textProvider.getText(this);
  }

  public setText(newText: string) {
    this.textProvider.setText(newText);
  }

  public addAnswer(answer: Answer) {
    this.answers.push(answer);
  }

  public setSelectedAnswer(answer: Answer) {
    this.selectedAnswer = answer;
  }

  public getSelectedAnswer(): Answer {
    if (!this.hasValidAnswer()) {
      throw new Error(
        "getAnswer called but question does not have a valid answer"
      );
    }

    if (this.getAnswerPresentationType() === AnswerPresentationType.Radio) {
      return this.answers.filter(a => a.responseGiven)[0];
    }

    return this.answers[0];
  }

  public getAnswerPresentationType(): AnswerPresentationType {
    if (
      this.answers.some(a => a.presentationType == AnswerPresentationType.Radio)
    ) {
      return AnswerPresentationType.Radio;
    } else if (
      this.answers.some(
        a => a.presentationType == AnswerPresentationType.DatePicker
      )
    ) {
      return AnswerPresentationType.DatePicker;
    } else {
      return AnswerPresentationType.Text;
    }
  }

  public hasValidAnswer(): boolean {
    const answerMap = this.getAnswerMap();

    if (this.getAnswerPresentationType() == AnswerPresentationType.Radio) {
      return answerMap.filter(am => am.responseGiven).length == 1;
    }

    if (this.customQuestionValidator != null) {
      return this.customQuestionValidator.isValid(this.answers);
    }

    return this.answers.every(a => a.isValidResponse().isValid);
  }

  public hasCustomValidator(): boolean {
    return this.customQuestionValidator != null;
  }

  public getCustomValidationMessage(): string {
    return this.customQuestionValidator.getFailureMessage(this.answers);
  }

  public getNextQuestion(): NextQuestionResponse {
    if (!this.hasValidAnswer()) {
      return new NextQuestionResponse(
        QuestionNavigationAction.CurrentQuestionUnanswered,
        null,
        null,
        null
      );
    }

    let selectedAnswer: Answer = null;
    if (this.getAnswerPresentationType() == AnswerPresentationType.Radio) {
      selectedAnswer = this.getSelectedAnswer();
    } else {
      selectedAnswer = this.answers[0];
    }

    const followUp = selectedAnswer.getFollowUp();

    const navAction = this.mapQuestionFollowupActionToQuestionNavigationAction(
      followUp.followUpAction
    );

    let workflowSection = null;
    if (navAction == QuestionNavigationAction.QuestionsCompleted) {
      workflowSection = WorkflowSection.UserCalculations;
    }

    let outcome: ServerOutcome = null;

    if ("outcome" in followUp) {
      outcome = (followUp as TerminationFollowUp).outcome;
    }

    return new NextQuestionResponse(
      navAction,
      followUp.question,
      workflowSection,
      outcome
    );
  }

  public hasFollowUpQuestion(): boolean {
    return this.answers.length > 0;
  }

  private mapQuestionFollowupActionToQuestionNavigationAction(
    answerFollowupAction: AnswerFollowupAction
  ): QuestionNavigationAction {
    switch (answerFollowupAction) {
      case AnswerFollowupAction.NextQuestion:
        return QuestionNavigationAction.NextQuestion;
      case AnswerFollowupAction.UserBlocked:
        return QuestionNavigationAction.UserBlocked;
      case AnswerFollowupAction.QuestionsCompleted:
        return QuestionNavigationAction.QuestionsCompleted;
      default:
        throw new Error("Unknown AnswerFollowupAction");
    }
  }

  private getAnswerMap(): {
    presentationType: AnswerPresentationType;
    responseGiven: boolean;
    response: any;
  }[] {
    return this.answers.map(a => ({
      presentationType: a.presentationType,
      responseGiven: a.responseGiven,
      response: a.response
    }));
  }
}

class Answer {
  public id = 0;
  public label = "";
  public presentationType: AnswerPresentationType;
  public responseGiven: boolean;
  public response: any;
  public hasDefaultResponse = false;
  public defaultResponse: any = null;
  private followUpQuestionEvaluator: FollowUpQuestionEvaluator;
  private validators: Array<AnswerValidator>;
  private delegatedResponse: string;

  constructor(
    id: number,
    label: string,
    followupQuestionEvaluator: FollowUpQuestionEvaluator,
    presentationType = AnswerPresentationType.Radio,
    validators: Array<AnswerValidator> = null,
    delegatedResponse: string = null,
    response: any = null
  ) {
    this.id = id;
    this.label = label;
    this.followUpQuestionEvaluator = followupQuestionEvaluator;
    this.presentationType = presentationType;
    this.validators = validators ?? new Array<AnswerValidator>();
    this.delegatedResponse = delegatedResponse;
    this.response = response;
  }

  public setDefaultResponse(defaultResponse: any) {
    this.hasDefaultResponse = true;
    this.defaultResponse = defaultResponse;
  }

  public setValidators(validators: Array<AnswerValidator>) {
    this.validators = validators ?? new Array<AnswerValidator>();
  }

  public bindResponse(response: any) {
    if (this.presentationType == AnswerPresentationType.Radio) {
      if (response) {
        this.response = this.delegatedResponse;
        this.responseGiven = true;
      } else {
        this.response = null;
        this.responseGiven = false;
      }
      return;
    } else if (this.presentationType == AnswerPresentationType.Text) {
      if (response === "") {
        this.responseGiven = false;
      } else {
        this.responseGiven = true;
      }
      this.response = response;
      return;
    }

    this.response = response;
    this.responseGiven = true;
  }

  public isValidResponse(): AnswerValidationResponse {
    if (!this.responseGiven) {
      return AnswerValidationResponse.DefaultFailureResponse();
    }

    if (this.presentationType == AnswerPresentationType.Radio) {
      return AnswerValidationResponse.Valid();
    }

    if (this.response == null || this.response.length == 0) {
      return AnswerValidationResponse.DefaultFailureResponse();
    }

    for (const validator of this.validators) {
      const validationResponse = validator.Validate(this.response);
      if (!validationResponse.isValid) {
        return validationResponse;
      }
    }

    return AnswerValidationResponse.Valid();
  }

  public getBooleanResponse(): boolean | null {
    if (!this.responseGiven) {
      return null;
    }

    if (this.presentationType != AnswerPresentationType.Radio) {
      return null;
    }

    return this.delegatedResponse == "Y";
  }

  public getFollowUp(): FollowUp {
    if (this.followUpQuestionEvaluator == null) {
      return null;
    }

    return this.followUpQuestionEvaluator.getFollowUp(this);
  }
}

export {
  Question,
  Answer,
  ConfirmationBehaviour,
  NextQuestionResponse,
  PreviousQuestionResponse,
  QuestionTextProvider,
  DefaultQuestionTextProvider,
  CustomQuestionValidator
};
