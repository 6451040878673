import { createStore } from "vuex";
import WorkflowSection from "../ts/workflow-section";
import { format } from "date-fns";
import axios from "axios";
import { questions } from "../ts/db-questions";
import Pot from "../ts/assets/pot";
import { StatePensionData } from "../ts/api/statePensionData";
import ServerOutcome from "../ts/outcome";
import { StoredAnswer } from "../ts/storedAnswer";
import { Configuration } from "../ts/configuration";

function getStartWorkflowSection(): WorkflowSection {
  const url = window.location.href;

  if (url.includes("localhost") || url.includes("test.")) {
    return WorkflowSection.Landing;
  }

  return WorkflowSection.Start;
}

export default createStore({
  state: {
    configuration: {} as Configuration,
    userId: "",
    serverOutcome: ServerOutcome.NotReached,
    serverAnswers: new Array<StoredAnswer>(),
    serverPots: new Array<Pot>(),
    serverStatePension: new StatePensionData(0, ""),
    dPanel: false,
    workflowSection: getStartWorkflowSection(),
    adminMode: false,
    keyQuestionsOnly: true,
    hasDeficitCalcCompleted: false,
    hasFinancialDeficit: false,
    calculatedReducedPension: 0,
    calculatedReducedPensionCash: 0,
    yesOutcome: false,
    statePensionData: new StatePensionData(0, "unknown"),
    allOtherAssets: new Array<Pot>()
  },
  mutations: {
    updateConfiguration(state, configuration) {
      state.configuration = configuration;
    },
    updateUserId(state, userId: string) {
      state.userId = userId;
    },
    updateServerOutcome(state, outcome: ServerOutcome) {
      state.serverOutcome = outcome;
    },
    updateServerAnswers(state, serverAnswers: Array<StoredAnswer>) {
      state.serverAnswers = serverAnswers;
    },
    updateServerPots(state, serverPots: Array<Pot>) {
      state.serverPots = serverPots;
    },
    updateServerStatePension(state, serverStatePension: StatePensionData) {
      state.serverStatePension = serverStatePension;
    },
    updateDPanel(state, on: boolean) {
      state.dPanel = on;
    },
    updateYesOutcome(state, yesOutcome: boolean) {
      state.yesOutcome = yesOutcome;
    },
    updateWorkflowSection(state, workflowSection) {
      const previousWorkflowSection = state.workflowSection;

      state.workflowSection = workflowSection;

      if (
        previousWorkflowSection === WorkflowSection.UserQuestions &&
        workflowSection === WorkflowSection.UserCalculations
      ) {
        const request = {
          dateOfBirth: questions.getDateOfBirthAnswer(),
          sex: questions.getSexAnswer(),
          yearsWorked: questions.getHowManyYearsWorking()
        };
        this.dispatch("getStatePensionDetail", request);
      }
    },
    setAdminMode(state) {
      state.adminMode = true;
    },
    updateStatePensionData(state, statePensionData) {
      state.statePensionData = statePensionData;
    },
    updateHasDeficitCalcCompleted(state, hasCompleted: boolean) {
      state.hasDeficitCalcCompleted = hasCompleted;
    },
    updateCalculatedReducedPension(state, reducedPension: number) {
      state.calculatedReducedPension = reducedPension;
    },
    updateCalculatedReducedPensionCash(state, reducedPensionCash: number) {
      state.calculatedReducedPensionCash = reducedPensionCash;
    },
    updateFinancialDeficit(state, hasDeficit: boolean) {
      state.hasFinancialDeficit = hasDeficit;
    },
    updateAllOtherAssets(state, allOtherAssets: Array<Pot>) {
      state.allOtherAssets = allOtherAssets;
    }
  },
  actions: {
    getReducedPensionValues({ commit }) {
      const reducedPension = questions.getReducedPension();
      if (reducedPension !== null) {
        const reducedPensionCash = questions.getReducedPensionCashAmount();
        commit("updateCalculatedReducedPension", reducedPension);
        commit("updateCalculatedReducedPensionCash", reducedPensionCash);
        return;
      }

      const fullPensionValue = questions.getDeclaredOrEstimatedFullPension();

      const params = {
        params: {
          fullPension: fullPensionValue
        }
      };

      const resource = "/DB/getReducedPensionValues";
      axios.get(resource, params).then(response => {
        commit(
          "updateCalculatedReducedPension",
          response.data.Data.reducedPension
        );
        commit(
          "updateCalculatedReducedPensionCash",
          response.data.Data.reducedPensionCash
        );
      });
    },
    getStatePensionDetail({ commit }, request) {
      const dateOfBirth = new Date(request.dateOfBirth);
      const amount = 11500;
      const entitlementDate = dateOfBirth.setFullYear(dateOfBirth.getFullYear() + 65);
      const fixedStatePensionInfo = new StatePensionData(amount, format(entitlementDate, "dd-MMM-yyyy"));

      commit("updateStatePensionData", fixedStatePensionInfo);
    }
  },
  modules: {}
});
