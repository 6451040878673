
import { defineComponent, ref } from "vue";
import { inputValidator } from "@/db/ts/inputValidator";
import Pot from "@/db/ts/assets/pot";
import IPotFactory from "@/db/ts/assets/iPotFactory";
import YesNoQuestion from "@/db/components/YesNoQuestion.vue";

export default defineComponent({
  name: "PotEditor",
  components: {
    YesNoQuestion
  },
  emits: ["save-clicked", "cancel-pots-edit"],
  props: {
    potFactory: { type: Object as () => IPotFactory, required: true },
    currentValueLabel: { type: String, default: "Cur value", required: true }
  },
  setup() {
    const uiCurrentValue = ref(null);
    const flagInvalidAnswer = ref(false);
    const intendToMakeContribs = ref(null);
    const willIncreaseContribs = ref(null);
    const monthlyContribValue = ref(null);
    const payableFrom = ref(null);
    const selectedPayableFromYear = ref(0);

    return {
      uiCurrentValue,
      monthlyContribValue,
      flagInvalidAnswer,
      intendToMakeContribs,
      willIncreaseContribs,
      payableFrom,
      selectedPayableFromYear
    };
  },
  data() {
    return {
      inputValidator,
      isNew: true,
      id: 0,
      name: "",
      currentValue: 0,
      monthlyFutureContrib: 0,
      showContribDetail: false,
      hideContribsSection: true,
      capturePayableFrom: false
    };
  },
  computed: {
    showValidation: function() {
      return this.flagInvalidAnswer;
    },
    buttonText: function(): string {
      return this.isNew ? "Add" : "Update";
    }
  },
  methods: {
    setValues(
      isNew: boolean,
      id: number,
      name: string,
      currentValue: number,
      willMakeFutureContribs: boolean,
      monthlyFutureContrib: number,
      futureContribsWillIncrease: boolean,
      capturePayableFrom: boolean,
      payableFrom: number
    ) {
      this.isNew = isNew;
      this.id = id;
      this.name = name;
      this.currentValue = "";
      this.currentValue = currentValue == 0 ? null : currentValue;
      this.intendToMakeContribs.setSelectedAnswer(willMakeFutureContribs);
      this.monthlyFutureContrib =
        monthlyFutureContrib == 0 ? null : monthlyFutureContrib;
      this.willIncreaseContribs.setSelectedAnswer(futureContribsWillIncrease);
      this.showContribDetail = willMakeFutureContribs;
      this.capturePayableFrom = capturePayableFrom;
      this.selectedPayableFromYear = payableFrom;
    },
    onSaveClicked: function() {
      if (!inputValidator.isNumber(this.uiCurrentValue.value, 1)) {
        this.flagInvalidAnswer = true;
        return;
      }

      this.flagInvalidAnswer = false;
      this.$emit("save-clicked");
    },
    getPot: function() {
      const answers = this.getAnswers();
      return this.potFactory.createPot(
        this.id,
        answers.currentValue,
        answers.willMakeFutureContribs,
        answers.monthlyContribValue,
        answers.willIncreaseContribs,
        answers.payableFrom
      );
    },
    updatePot: function(pot: Pot) {
      const answers = this.getAnswers();
      pot.currentValue = answers.currentValue;
      pot.willMakeFutureContribs = answers.willMakeFutureContribs;
      pot.monthlyFutureContrib = answers.monthlyContribValue;
      pot.futureContribsWillIncrease = answers.willIncreaseContribs;
      pot.payableFrom = answers.payableFrom;
    },
    getAnswers() {
      return {
        currentValue: this.uiCurrentValue.value,
        willMakeFutureContribs: this.intendToMakeContribs.answeredYes(),
        monthlyContribValue: this.monthlyContribValue.value,
        willIncreaseContribs: this.willIncreaseContribs.answeredYes(),
        payableFrom: this.selectedPayableFromYear
      };
    },
    onIntendToMakeContribsAnswerChanged() {
      this.showContribDetail = this.intendToMakeContribs.answeredYes();
    },
    getYearsPayableFrom() {
      const currentYear = new Date().getFullYear();
      const allYears = [];
      const lastYear = currentYear + 10;

      for (let i = currentYear; i <= lastYear; i++) {
        allYears.push(i);
      }

      return allYears;
    }
  }
});
