import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76423a5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-item bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(["bi", [_ctx.calculateWorkflowImgClass(), _ctx.sectionCompletedClass()]])
    }, null, 2),
    _createElementVNode("span", {
      class: _normalizeClass(["nav-link", [_ctx.isActive ? 'active' : '']])
    }, _toDisplayString(_ctx.elementText), 3)
  ]))
}