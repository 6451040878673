
import { defineComponent } from "vue";
import WorkflowSection from "@/db/ts/workflow-section";

export default defineComponent({
  name: "NavElement",
  props: {
    section: {
      required: true,
      default: () => WorkflowSection.EducationVideo
    }
  },
  /*data() {

        },*/
  methods: {
    calculateWorkflowImgClass() {
      switch (this.section) {
        case WorkflowSection.EducationVideo:
          return "bi-youtube";
        case WorkflowSection.UserQuestions:
          return "bi-person-circle";
        case WorkflowSection.UserCalculations:
          return "bi-calculator";
        case WorkflowSection.RiskInsights:
          return "bi-exclamation-triangle";
        case WorkflowSection.FCAStatement:
          return "bi-shield-check";
        default:
          return "bi-chat-text";
      }
    },
    sectionCompletedClass() {
      const thisSectionAsNumber = this.section as number;
      const currentSectionAsNumber = this.$store.state
        .workflowSection as number;
      if (thisSectionAsNumber < currentSectionAsNumber) {
        return "section-completed animate__animated animate__bounce";
      }
    }
  },
  computed: {
    elementText() {
      switch (this.section) {
        case WorkflowSection.EducationVideo:
          return "Education";
        case WorkflowSection.UserQuestions:
          return "Information";
        case WorkflowSection.UserCalculations:
          return "Calculation";
        case WorkflowSection.RiskInsights:
          return "Risk";
        case WorkflowSection.FCAStatement:
          return "FCA View";
        default:
          return "Advice";
      }
    },
    isActive() {
      return this.$store.state.workflowSection == this.section;
    }
  }
});
