import {
  Question,
  PreviousQuestionResponse,
  NextQuestionResponse,
  QuestionNavigationAction
} from "./db-core";
import { questions, TargetIncomeCalculator } from "../ts/db-questions";
import WorkflowSection from "./workflow-section";
import Temporal from "../../shared/ts/temporal";
import AnswerValidator from "./answer-validator";

class QuestionNavigator {
  private showKeyQuestionsOnly = false;
  private keyQuestions: Array<Question>;
  public answeredQuestions: Array<Question>;

  public constructor() {
    this.answeredQuestions = new Array<Question>();
  }

  public setShowKeyQuestionsOnly(showKeyQuestionsOnly: boolean) {
    if (showKeyQuestionsOnly) {
      this.keyQuestions = questions.all.filter(q => q.isKeyQuestion).reverse();
    }
    this.showKeyQuestionsOnly = showKeyQuestionsOnly;
  }

  public configureForOrigen() {
    questions.startTakingPensionSoon.setText("Do you want to start taking some cash or an income from your current scheme in the next 12 months?");

    const dobMaxAgeValidationFunction = (userAnsweredDOB: Date) => {
      const age = Temporal.calcAge(userAnsweredDOB);
      const maxAge = 64;
      return age <= maxAge;
    };
    const ageValidators = [
      new AnswerValidator(
        dobMaxAgeValidationFunction,
        "You must be under 64 to use this website."
      )
    ];
    questions.dateOfBirthQuestion.answers[0].setValidators(ageValidators);
  }

  public getFirstQuestion() {
    if (!this.showKeyQuestionsOnly) {
      return questions.firstQuestion;
    }
    return this.keyQuestions[0];
  }

  public isFirstQuestion(question: Question) {
    const firstQuestion = this.getFirstQuestion();
    return question.id === firstQuestion.id;
  }

  public getPreviousQuestion(): PreviousQuestionResponse {
    const previousQuestion = this.answeredQuestions.pop();
    const previousQuestionResponse = new PreviousQuestionResponse(
      previousQuestion
    );
    return previousQuestionResponse;
  }

  public getNextQuestion(question: Question): NextQuestionResponse {
    this.answeredQuestions.push(question);

    let nextQuestionResponse = question.getNextQuestion();

    if (nextQuestionResponse.nextQuestion != null && nextQuestionResponse.nextQuestion.isHidden) {
      do {
        nextQuestionResponse = nextQuestionResponse.nextQuestion.getNextQuestion();

        if (nextQuestionResponse == null || nextQuestionResponse.nextQuestion == null) {
          break;
        }

      } while (nextQuestionResponse.nextQuestion.isHidden)
    }

    if (!this.showKeyQuestionsOnly) {
      const transferValueQuestion = questions.transferValueAmountQuestion;
      if (question.id == transferValueQuestion.id) {
        const isInIllHealth = questions.getIllHealthQuestionAnswer();
        if (isInIllHealth) {
          return new NextQuestionResponse(
            QuestionNavigationAction.QuestionsCompleted,
            null,
            WorkflowSection.RiskInsights,
            null
          );
        }
      }

      if (question.id == questions.lifeExpectancyQuestion.id) {
        const hasReducedLifeExpectancy = questions.getHasShortenedLifeExpectancyAnswer();
        if (hasReducedLifeExpectancy) {
          return new NextQuestionResponse(
            QuestionNavigationAction.QuestionsCompleted,
            null,
            WorkflowSection.RiskInsights,
            null
          );
        }
      }

      if (question.id == questions.lifestyle.id) {
        const targetIncomeCalculator = new TargetIncomeCalculator();
        const willRetireInLondon = questions.getWillRetireInLondon();
        const isSupportingPartner = false;
        const getDesiredLifeStyle = questions.getDesiredLifeStyle();
        const suggestedIncome = targetIncomeCalculator.calcSuggestedRetirementIncome(
          willRetireInLondon,
          isSupportingPartner,
          getDesiredLifeStyle
        );
        questions.income.answers[0].responseGiven = true;
        questions.income.answers[0].response = suggestedIncome;
      }

      return nextQuestionResponse;
    }

    let questionIndexInKeyQuestions = 0;
    let questionMatched = false;
    for (const keyQuestion of this.keyQuestions) {
      if (keyQuestion.id == question.id) {
        questionMatched = true;
        break;
      }
      questionIndexInKeyQuestions++;
    }

    if (!questionMatched) {
      throw new Error("Current question not found in key question list.");
    }

    const isLastQuestion =
      questionIndexInKeyQuestions == this.keyQuestions.length - 1;
    if (isLastQuestion) {
      return new NextQuestionResponse(
        QuestionNavigationAction.QuestionsCompleted,
        null,
        WorkflowSection.UserCalculations,
        null
      );
    }

    const nextIndex = questionIndexInKeyQuestions + 1;
    return new NextQuestionResponse(
      QuestionNavigationAction.NextQuestion,
      this.keyQuestions[nextIndex],
      null,
      null
    );
  }

  public getIllHealthQuestion(): Question {
    return questions.illHealthQuestion;
  }

  public getTransferValueTooLowQuestion(): Question {
    return questions.transferValueAmountQuestion;
  }
}

const questionNavigator = new QuestionNavigator();

export { questionNavigator };
